<template>
  <navigation index="reportMarket" ref="navigation">
    <div
      class="topnavigation"
      style="height: 60px; width: 100%; display: flex; align-items: center"
    ></div>
    <el-scrollbar
      :style="{ height: clientHeight - 80 + 'px' }"
      style="width: calc(100%-30px)"
    >
      <br />
      <el-card
        class="box-card"
        style="
          margin-top: 100px;
          width: calc(100% - 10px);
          margin: 0 auto;
          border: 1px solid var(--el-menu-border-color);
        "
      >
        <template #header>
          <span> {{ tools.GetLanguageValue("web.销售信息") }} </span>
        </template>

        <el-row style="padding: 20px; text-align: center">
          <el-col :span="6">
            <!-- <el-countdown title="当前时间" format="HH:mm:ss" :value="Time" /> -->
            <el-statistic
              :title="tools.GetLanguageValue('web.总销售额')"
              :value="MarketSummarizing.AllTotal"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.本周')"
              :value="MarketSummarizing.WeekTotal"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.今年')"
              :value="MarketSummarizing.YearTotal"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.上周')"
              :value="MarketSummarizing.LastMonthTotal"
            >
            </el-statistic>
          </el-col>
        </el-row>

        <el-row style="padding: 20px; text-align: center">
          <el-col :span="6">
            <!-- <el-countdown title="当前时间" format="HH:mm:ss" :value="Time" /> -->
            <el-statistic
              :title="tools.GetLanguageValue('web.本月')"
              :value="MarketSummarizing.MonthTotal"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.昨日')"
              :value="MarketSummarizing.YesterdayTotal"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.今日')"
              :value="MarketSummarizing.DayDateTotal"
            />
          </el-col>
          <el-col :span="6">
            <!-- <el-statistic
                :title="tools.GetLanguageValue('web.日期')"
                :value="MarketSummarizing.DateFormat"
              >
              </el-statistic> -->
          </el-col>
        </el-row>
      </el-card>
 
        <el-row style="padding: 20px; text-align: center">
          <el-col :span="6">
            <el-card class="profit_content">
              <template #header>
                <div class="card-header">
                  <div>{{ tools.GetLanguageValue("web.今日") }}</div>
                </div>
              </template>

              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.营业额") }}</div>
                <div>{{ CollectProfit.Today.Total }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.成本") }}</div>
                <div>{{ CollectProfit.Today.Cast }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.利润") }}</div>
                <div>{{ CollectProfit.Today.Profit }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.无成本销售额") }}</div>
                <div>{{ CollectProfit.Today.LackCast }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="profit_content">
 
              <template #header>
                <div class="card-header">
                  <div>{{ tools.GetLanguageValue("web.昨日") }}</div>
                </div>
              </template>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.营业额") }}</div>
                <div>{{ CollectProfit.Yesterday.Total }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.成本") }}</div>
                <div>{{ CollectProfit.Yesterday.Cast }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.利润") }}</div>
                <div>{{ CollectProfit.Yesterday.Profit }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.无成本销售额") }}</div>
                <div>{{ CollectProfit.Yesterday.LackCast }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="profit_content">
              <template #header>
                <div class="card-header">
                  <div>{{ tools.GetLanguageValue("web.本月") }}</div>
                </div>
              </template>
             
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.营业额") }}</div>
                <div>{{ CollectProfit.MonthThis.Total }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.成本") }}</div>
                <div>{{ CollectProfit.MonthThis.Cast }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.利润") }}</div>
                <div>{{ CollectProfit.MonthThis.Profit }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.无成本销售额") }}</div>
                <div>{{ CollectProfit.MonthThis.LackCast }}</div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="profit_content">
              <template #header>
                <div class="card-header">
                  <div>{{ tools.GetLanguageValue("web.近30天") }}</div>
                </div>
              </template>
            
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.营业额") }}</div>
                <div>{{ CollectProfit.To30day.Total }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.成本") }}</div>
                <div>{{ CollectProfit.To30day.Cast }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.利润") }}</div>
                <div>{{ CollectProfit.To30day.Profit }}</div>
              </div>
              <div class="profit_content_items">
                <div>{{ tools.GetLanguageValue("web.无成本销售额") }}</div>
                <div>{{ CollectProfit.To30day.LackCast }}</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
     

      <br />
      <el-card
        class="box-card"
        style="
          width: calc(100% - 10px);
          margin: 0 auto;
          border: 1px solid var(--el-menu-border-color);
        "
      >
        <template #header>
          <span> {{ tools.GetLanguageValue("web.30天走势") }} </span>
        </template>

        <div id="main" style="width: 100%; height: 400px"></div>
      </el-card>
    </el-scrollbar>
  </navigation>
</template>
    <script>
import axios from "../../../commons/AxiosMethod.js";
import { ElMessageBox, ElMessage } from "element-plus";
import * as echarts from "echarts";
import navigation from "../../../components/Modules/Navigation.vue";
import tools from "../../../commons/tools.js";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      MarketSummarizing: {}, //汇总销售额
      tools: tools,
      CollectProfit: {
        Today: {},
        Yesterday: {},
        WeekThis: {},
        MonthThis: {},
        To30day: {},
      },
    };
  },
  name: "App",
  mounted() {
    this.GetStoreOrderBy30Day();
    this.GetMarketSummarizingOrder();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.MakertItemHeight = document.documentElement.clientHeight - 370;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
    this.MakertItemHeight = this.clientHeight - 370;

    this.GetMarketCollectProfit();
  },

  components: { navigation, tools },
  methods: {
    //获取利润汇总信息
    GetMarketCollectProfit() {
      axios.apiMethod(
        "/report/MarketReport/GetMarketCollectProfit",
        "get",
        {},
        (result) => {
          this.CollectProfit = result.Data;
          // Tday = today, Yesterday= yesterday, WeekThis = weekThis, MonthThis = monthThis
        },
        (d) => {}
      );
    },
    //获取30天的折线图
    GetStoreOrderBy30Day() {
      axios.apiMethod(
        "/report/MarketReport/GetStoreOrderBy30Day",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("main");
          var myChart = echarts.init(chartDom);

          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: result.Data.data,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: result.Data.SeriesDataItems,
                type: "bar",
              },
            ],
          };

          myChart.setOption(option);
        },
        (d) => {}
      );
    },
    //获取汇总
    GetMarketSummarizingOrder() {
      axios.apiMethod(
        "/report/MarketReport/GetMarketSummarizingOrder",
        "get",
        {},
        (result) => {
          this.MarketSummarizing = result.Data;
        },
        (d) => {}
      );
    },
    //今日维修信息
    GetRepairsToday() {
      axios.apiMethod(
        "/report/StoreReport/GetcombineRepairsTodayAndYesterday",
        "get",
        {},
        (result) => {
          this.RepairsToday = result.Data.RepairsToday;
          this.RepairsYesterday = result.Data.RepairsYesterday;
        },
        (d) => {}
      );
    },
    skip(url) {
      this.$router.push({
        path: url,
        query: {
          s: Date.now(),
        },
      });
    },
  },
};
</script>
      
    <style scoped lang="less">
.flex-grow {
  flex-grow: 1;
}

/* <div class="profit_content">
              <div  class="profit_content_items"> */

.profit_content {
  border: 1px solid #eee;
  padding: 10px;
  width: calc(100% - 50px);
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 18px;
}

.profit_content_items {
  display: flex;
  justify-content: space-between;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 5px;
}
</style>
     